import 'regenerator-runtime';
import { yiluEnv, renderYiluApp } from '@yiluhub/travel-integration';

import { overrides } from './overrides/map';
import { iconOverrides } from './overrides/Icons';

import './styles/index.scss';

yiluEnv.setVariables({
  YILU_API_PRODUCTION_ENVIRONMENT: import.meta.env.VITE_YILU_API_PRODUCTION_ENVIRONMENT,
  GOOGLE_MAPS_KEY: import.meta.env.VITE_GOOGLE_MAPS_KEY,
  GOOGLE_ANALYTICS_MEASUREMENT_ID: import.meta.env.VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID,
  GOOGLE_TAG_MANAGER_CONTAINER_ID: import.meta.env.VITE_GOOGLE_TAG_MANAGER_CONTAINER_ID,
  HOTJAR_ID: import.meta.env.VITE_HOTJAR_ID,
  LOCALES_HOST_URL: import.meta.env.VITE_LOCALES_HOST_URL,
  PHONE_COUNTRY_CODE: import.meta.env.VITE_PHONE_COUNTRY_CODE,
  YILU_ENTERPRISE_PARTNER: import.meta.env.VITE_YILU_ENTERPRISE_PARTNER,
  FAQ_TYPE_FORM_EN: 'b7A7ng8J',
  FAQ_TYPE_FORM_DE: 'LNUYPHQC',

  AMP_STORE_ID: import.meta.env.VITE_AMP_STORE_ID,
  AMP_LOUNGE_VERTICAL_ID: import.meta.env.VITE_AMP_LOUNGE_VERTICAL_ID,
  AMP_LOUNGE_CATALOG_ID: import.meta.env.VITE_YILU_AMP_LOUNGE_CATALOG_ID,

  YILU_AMP_BACKEND_URL: import.meta.env.VITE_YILU_AMP_BACKEND_URL,
  YILU_AMP_STORE_ID: import.meta.env.VITE_YILU_AMP_STORE_ID,
  YILU_AMP_LOUNGE_VERTICAL_ID: import.meta.env.VITE_YILU_AMP_LOUNGE_VERTICAL_ID,
  YILU_AMP_PARKING_VERTICAL_ID: import.meta.env.VITE_YILU_AMP_PARKING_VERTICAL_ID,
  YILU_AMP_AIRPORT_TRANSFER_VERTICAL_ID: import.meta.env.VITE_YILU_AMP_AIRPORT_TRANSFER_VERTICAL_ID,
  YILU_AMP_AIRPORT_TRANSFER_PASSES_VERTICAL_ID: import.meta.env
    .VITE_YILU_AMP_AIRPORT_TRANSFER_PASSES_VERTICAL_ID,
  YILU_AMP_RIDES_VERTICAL_ID: import.meta.env.VITE_YILU_AMP_RIDES_VERTICAL_ID,
  YILU_AMP_CAR_RENTAL_VERTICAL_ID: import.meta.env.VITE_YILU_AMP_CAR_RENTAL_VERTICAL_ID,
  YILU_AMP_LOUNGE_CATALOG_ID: import.meta.env.VITE_YILU_AMP_LOUNGE_CATALOG_ID,
  YILU_AMP_MEILI_CATALOG_ID: import.meta.env.VITE_YILU_AMP_MEILI_CATALOG_ID,

  HIGHPASS_API_KEY: import.meta.env.VITE_HIGHPASS_API_KEY,
  HIGHPASS_STYLES: {
    backgroundColor: '#ffffff',
    buttonColor: '#d81e05',
    inputColor: '#F8F9FD',
    linkColor: '#ac1804',
    headingColor: '#d81e05',
    defaultTextColor: '#000000',
    errorColor: '#FF0000',
    serviceDescriptionColor: '#000000',
    labelColor: '#333333',
    importantColor: '#000000',
    headerTextColor: '#000000',
    descriptionTextColor: '#000000',
  },
});

renderYiluApp({
  overrides,
  iconOverrides,
});
